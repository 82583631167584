@import url("colors.css");
@import url("https://static.blue.cl/fonts/fonts-v2.css");

body,
html {
    height: 100%;
    width: 100%;
    font-family: "Lato";
    //media querie tablet & desktop
    @media screen and (min-width: 768px) {
        overflow: hidden;
    }
    @media screen and (max-width: 767px) {
        overflow: auto;
    }
}
#root {
    height: 100%;
}
div#content {
    width: 100%;
    height: 100%;
}

@font-face {
    font-family: "Lato";
    src: url("../../public/assets/fonts/Lato-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.searchbar-container {
    position: sticky;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 767px) {
        position: relative;
        top: 0;
        transform: translate(0);
    }
    
}


.search-bar {
    max-width: 900px;
}
.autocomplete-container {
    max-width: 900px;
    //top: 60px;
    position: absolute;
    z-index: 9999999;
    margin-top: 52px;
    // media querie mobile
    @media (max-width: 767px) {
        //top: 140px;
        max-width: 330px;
    }
}



.search-icon {
    position: absolute;
    top: 14px;
    left: 16px;
    color: #2bb9ff;
}

.map-and-listbox-container-w-nobanner{
    //height: calc(100vh - 118px);
    height: 100%;
    width: 100%;
    flex: 1;


    @media (max-width: 767px) {
        height: 100vh;
        display: flex;
        flex-direction: column;
        
    }
}

.map-and-listbox-container-w-banner{
    height: 100%;
    width: 100%;

    @media (max-width: 767px) {
        height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;

    }
}

.overflow-y-hidden{
    overflow-y: hidden;
}



.list-box-container {
    position: absolute;
    z-index: 100;
    background: rgb(255, 255, 255);
    width: 100%;
    max-width: 500px;
    margin: 0 0 0 0px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 0;
    max-height: 100%;
    overflow-y: auto;

    //media screen tablet
    @media (min-width: 768px) {
        max-width: 310px;
        top: 0;
    }
    //media screen desktop
    @media (min-width: 992px) {
        max-width: 350px;
        top: 0;
    }
    //media screen mobile
    @media (max-width: 767px) {
        max-width: 100%;
        margin: 0;
        border-radius: 0;
        bottom: 0;
        position: relative;
        // transform: translateY(-40vh);
        height: auto;
        max-height: initial;
        top: initial;
        
        overflow-y: scroll;
        flex: 1;
    }

    
}

// .list-box-container-scroll-w-banner{
//     //cel
//     @media (max-width: 767px) {
//         max-height: calc(100vh - 463px);
//     }
// }
// .list-box-container-scroll-w-nobanner{
//     //cel
//     @media (max-width: 767px) {
//         max-height: calc(100vh - 387px);
//     }
// }

.list-box {
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem 0.75rem;
    pointer-events: none;

}
.list-box.disabled {
    background-color: rgba(229, 229, 229, 1) !important;

}

.list-box:hover {
    background-color: #eaf8ff;
}

.list-box .highlight{
    pointer-events: auto;
}

.list-box .details{
    pointer-events: auto;
    
}

.details-text{
    text-decoration-line: underline;
    font-size: 14px;
    //margin-bottom: 4px;
}




.icon-container {
    width: 24px;
    //text-align: center;
}

.text-km {
    //color: #ff7e44;
    white-space: nowrap;
}






.btn-options {
    background-color: #ff7e44;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    //padding: 0.75rem 0.5rem;
    border-radius: 12px;
    //box-shadow: 0px 0px 4px 2px rgba(24, 39, 75, 0.2);
    

    &:hover {
        background-color: #e66a2e;
        color
        : white;
    }
    &:focus{
        background-color: #e05d1b;
        color: white;
    }
    &:focus{
        background-color: #e05d1b;
        color: white;
    }
    &:active {
        background-color: #d95b2a !important;
        color: white !important;
    }
    @media screen and (max-width: 767px) {
        padding-left: 8px;
        padding-right: 8px;
        height: auto;
    }
}

.btn-options-white {
    background-color: #ffffff;
    color: #ff7e44;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    //padding: 0.75rem 0.5rem;
    border-radius: 12px;
    border: 1.5px solid rgba(255, 126, 68, 1);
    //box-shadow: 0px 0px 1px 2px rgba(24, 39, 75, 0.2);
    &:hover {
        background-color: #e66a2e;
        color: white;
    }
    &:active {
        background-color: #d95b2a !important;
        color: white !important;
    }
}



.btn-banner-orange {
    background-color: #ff7e44;
    color: white;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    //min-width: 200px;
    //padding: 0.75rem 0.5rem;
    border-radius: 12px;
    &:hover {
        background-color: #e66a2e;
        color: white;
    }
    &:focus{
        background-color: #e05d1b;
        color: white;
    }
    &:active {
        background-color: #d95b2a !important;
        color: white !important;
    }
}

.btn-banner-white {
    background-color: #ffffff;
    color: #ff7e44;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    //min-width: 200px;
    //padding: 0.75rem 0.5rem;
    border-radius: 12px;
    border: 1.5px solid rgba(255, 126, 68, 1);
    &:hover {
        background-color: #e66a2e;
        color: white;
    }
    &:focus{
        background-color: #e05d1b;
        color: white;
    }
    &:active {
        background-color: #d95b2a !important;
        color: white !important;
    }
}

.btn-banner-dynamic-white{
    background-color: #ffffff;
    color: #ff7e44;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    //min-width: 200px;
    //padding: 0.75rem 0.5rem;
    border-radius: 12px;
    border: 1.5px solid rgba(255, 126, 68, 1);
    &:hover {
        background-color: #e66a2e;
        color: white;
    }
    &:focus{
        background-color: #e05d1b;
        color: white;
    }
    &:active {
        background-color: #d95b2a !important;
        color: white !important;
    }

    @media screen and (max-width: 767px) {
        
        background-color: #ff7e44;
        color: white;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        //min-width: 200px;
        //padding: 0.75rem 0.5rem;
        border-radius: 12px;
        &:hover {
            background-color: #e66a2e;
            color: white;
        }
        &:focus{
            background-color: #e05d1b;
            color: white;
        }
        &:active {
            background-color: #d95b2a !important;
            color: white !important;
        }
    }
}

.text-hl {
    color: #ff7e44;
}

.color-text-blue {
    color: #0032a0;
}
.color-text-skyblue {
    color: rgba(43, 185, 255, 1);
}
.color-text-orange{
    color: #ff7e44;
}

.cursor-pointer {
    cursor: pointer;
}

.selected-list-box {
    border-radius: 4px;
    animation: highlight 1s ease-in-out;
    animation-fill-mode: forwards;
    background-color: #d5f1ff;

    @keyframes highlight {
        0% {
            background-color: #f4f4f4;
        }
        
        100% {
            background-color: #d5f1ff;
        }
    }
    
}

.text-main {
    font-size: 1rem;
    font-weight: 800;
    //color: #0032a0;
}



.map-container {
    //height: 100% !important;
    width: auto !important;
    //mobile meque querie
    @media (max-width: 767px) {
        //position: absolute !important;
        margin-left: 0px;
        height: 60% !important;
    }


    //media screen tablet
    @media (min-width: 768px) {
        margin-left: 310px;
        //width: 100%;
    }
    //media screen desktop
    @media (min-width: 992px) {
        margin-left: 350px;
        //width: 100%;

    }
    
}

.scroll-down-bar {

    @media (min-width: 768px) {
        display: none;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 0 1rem;

    .bar {
        width: 40%;
        max-width: 120px;
        background-color: #e5e5e5;
        height: 8px;
        border-radius: 6px;
        margin-bottom: 1rem;
    }
}


.location-search-input {

    border-color: rgba(26, 111, 153, 1);
    border-radius: 16px;
    height: 50px;
    


    
    @media screen and (max-width: 767px) {
        padding-right: 3rem;
        
    }
}




.container-filters { 
    max-width: none;

    @media (min-width: 768px) {
        max-width: 100%;
        padding: 0% 10% 0% 10%;

    }
    //media screen desktop
    @media (min-width: 992px) {
        max-width: 100%;
        padding: 0% 10% 0% 10%;

    }
    
}

.py-cel-1{
    //cel
    @media screen and (max-width: 767px) {
        padding-top: 2px;
        padding-bottom: 6px;
        
    }
}

.card-8px{
    border-radius: 8px;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.4);
    padding: 8px 8px 0px;
    background-color: #ffffff;
}
.card-16px{
    border-radius: 16px;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.4);
    padding: 8px;
    background-color: #EAF8FF;
}



.day-selected{
    border-bottom: #2BB9FF;
    border-bottom-style: solid;
}

.day-not-selected{
    color: #808080;
}



.ps-3-5 {
    padding-left: 3rem;
}

.ps-2 {
    padding-left: 2rem;
}

.p-0-3rem{
    padding: 0.3rem 0.3rem !important;
}

.fs-sm {
    font-size: 0.875rem;
}
.fs-xs {
    font-size: 0.75rem;
}



.fs-12px{
    font-size: 12px ;
}
.fs-13px{
    font-size: 13px !important;
}
.fs-14px{
    font-size: 0.875rem;
}
.fs-16px{
    font-size: 16px;
}
.fs-18px{
    font-size: 18px;
}
.fs-20px{
    font-size: 20px;
}

.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.fw-800{
    font-weight: 800;
}
.font-lato{
    font-family: "Lato";
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}
  
.popup {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.9);
}

.icon-social-network{
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.9);
    border-radius: 100%;
}

.z-index-99999{
    z-index: 99999;
}
.z-index-9999 {
    z-index: 9999;
}

.lazyload{
    background: linear-gradient(to right, #e3e3e3, #e3e3e3, #e3e3e3,#f8f0f0, #e3e3e3, #e3e3e3, #e3e3e3) ;
    background-size: 400%;
    border-radius: 8px;
    width: 100%;
    height: 24px;


    animation: shine 2s infinite ease-in-out;
    animation-fill-mode: forwards;
    @keyframes shine {
        0%{background-position: left;} /*por defecto*/
        100%{background-position: right;}
    }

}

.lazyload-fast{
    background: linear-gradient(to right, #e3e3e3, #e3e3e3, #e3e3e3,#f8f0f0, #e3e3e3, #e3e3e3, #e3e3e3) ;
    background-size: 400%;
    border-radius: 8px;
    width: 100%;
    height: 24px;


    animation: shine 1s infinite forwards;
    animation-fill-mode: forwards;
    @keyframes shine {
        0%{background-position: left;} /*por defecto*/
        100%{background-position: right;}
    }

}

.banner{
    
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(33, 33, 33, 0.25);
    border-radius: 0px 0px 10px 10px;
}

.center-button{
    border: none;
    border-radius: 50px;
    box-shadow: 0px 4px 12px rgba(33, 33, 33, 0.25);
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-bottom: 120px;
}

.hide-on-cellphone{
    @media screen and (max-width: 767px) {
        display: none;        
    }
}
.hide-on-desktop-and-tablet{
    //media screen tablet
    @media (min-width: 768px) {
        display: none;
    }
    //media screen desktop
    @media (min-width: 992px) {
        display: none;

    }
}

.banner-searchbar-container{
    width: 100%;
    //position: absolute;
}

.position-absolute-important{
    position: absolute !important;
}


.custom-checkbox {
    border-color: rgb(255, 126, 68, 1)!important;
}
.custom-checkbox:checked {
    background-color:rgba(255, 126, 68, 1)!important;
    border-color: rgb(255, 126, 68, 1)!important;
}

.border-radius-8px{
    border-radius: 8px;
}

.bg-color-24-7{
    // background: rgba(254, 236, 210, 1) !important;
    background-color: rgba(125, 213, 157, 1);
    
}



.h-27px{
  height: 27px;
}

.h-40px{
    height: 40px;
}

.h-80px{
  height: 80px;
}

.mt-80px{
  margin-top: 80px;
}
.mt-15px{
  margin-top: 15px;
}
.w-85{
  width: 85%;
}
.h-90{
  height: 90%
}
.max-width{
    max-width: 300px ;
}
.container-switch-buttons{
    position: absolute;
    padding: 4px;
    bottom: 5px;
    right: 2%;
    background: #E5E5E5;
    width: 255px;
    height: 40px;
    border-radius: 12px;
    //left: calc(100vh - );

    @media (max-width: 767px) {
        position:relative;

    }
}

.btn-switch{
    background: #E5E5E5;
    color: rgba(128, 128, 128, 1);
    height: 33px;
    padding: 4px 7px 4px 7px;

    border-radius: 12px;
    font-size: 14px;
    font-weight: 700;
    max-width: 125px;
    min-width: 110px;
    //text-align: center;
}
.btn-switch:hover{
    background: #c1c1c1;
    color: rgba(128, 128, 128, 1);
}
.btn-switch-active{
    background: #0032A0 !important;
    color: #FFFFFF !important;
    
}
.filter-combobox-container {
    position: relative;

    @media (min-width: 768px) {
        max-width: 300px;
        min-width: 250px;

    }
    //media screen desktop
    @media (min-width: 992px) {
        max-width: 300px;
        min-width: 250px;

    }

}
.filter-combobox {
    border-color: rgba(26, 111, 153, 1);
    border-radius: 12px;
    height: 40px;

    color: #212121;

   
    letter-spacing: 0.03em

}

.dropdown-menu-container{
    position: absolute;
    top: 42px;
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    z-index: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.dropdown-item-container{
    //padding: 5px 0;
    border-top: 1px solid rgba(0,0,0,0.1);
    // margin-bottom: 0;
    // padding-inline-start: 15px;
    // padding-inline-end: 15px;
    // list-style: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
    padding: 6px 12px 6px 12px;
    cursor: pointer;
}

.checked2{
    background-color: #0032A0 ;
    color: rgba(255, 255, 255, 1);

}
.dropdown-item-container:first-child{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-top: none;
    padding-top: 7px;
}
.dropdown-item-container:last-child{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-bottom: 7px;
}
.dropdown-item-container:hover{
    //border-radius: 16px;
    background-color: rgba(51, 99, 255, 1);
    color: rgba(255, 255, 255, 1);

}


.dropdown-item-container .dropdown-item{
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.03em;
    //margin: 10px auto;
}

.active-filter-label{
    color: #0032A0;
    font-size: 14px;
    font-weight: 800;
    line-height: 18.9px;
    letter-spacing: 0.03em;

    border: 1px solid #0032A0;
    border-radius: 10px;
    // width: fit-content;
    padding: 1px 8px 1px 12px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px
    
}

.side-filters-container{
    position: absolute;
    background-color: #fff;
    width: 360px;
    left: -360px;
    bottom:0;
    height: 100%;
    z-index: 999999;
    padding: 18px;
    //overflow: auto;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);

    transition: left 0.3s ease;

    @media (max-width: 767px) {
        width: 100%;
        height: 85%;
        left: 0;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        bottom: -85%;
        transition: bottom 0.3s ease;
    }
}

.side-filters-container.open{
    left: 0;

    @media (max-width: 767px) { 
        bottom: 0;   
    }
}

.side-filters-full-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    z-index: 99999;
    //transition: background-color 0.3s;
}
.side-filters-full-container.open{
    opacity: 1;
    visibility: visible;
}


.side-filter-button{
    color: #0032A0;
    background-color: #fff;
    font-size: 14px;
    font-weight: 800;
    line-height: 18.9px;
    letter-spacing: 0.03em;

    border: 1px solid #0032A0;
    border-radius: 12px;
    // width: fit-content;
    padding: 8px 16px 8px 16px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: background-color 0.3s, color 0.3s;
}

.side-filter-button-checked{
    color: #fff;
    background-color: #0032A0;
    font-size: 14px;
    font-weight: 800;
    line-height: 18.9px;
    letter-spacing: 0.03em;

    border: 1px solid #0032A0;
    border-radius: 12px;
    // width: fit-content;
    padding: 8px 16px 8px 16px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: background-color 0.3s, color 0.3s;

    
}

.point-details-cel-container{
    position: absolute;
    background-color: #fff;
    //bottom: 100px;
    z-index: 999999;
    padding: 18px;
    //overflow: auto;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
    //visibility: hidden;
    transition: bottom 0.3s ease; //visibility 0.3s ease;

    @media (max-width: 767px) {
        width: 100%;
        height: 55%;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        bottom: -55%;
        //visibility: visible;

    }
}
.point-details-cel-container.open{
    @media (max-width: 767px) {
        //visibility: visible;
        bottom: 0;
    }
}
.point-details-cel-full-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    z-index: 99999;
    //transition: background-color 0.3s;
}
.point-details-cel-full-container.open{
    opacity: 1;
    visibility: visible;
}



